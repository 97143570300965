<template>
  <div class="container">
    <DetailTop :businessResp="userInfo.businessResp" :personalResp="userInfo.personalResp" />

    <a-form-model ref="form" :rules="rules" style="margin-top: 20px" :model="formData" :colon="false">
      <template title="企业真实性认证" v-if="authType === 3">
        <a-form-model-item label="企业真实性认证" prop="enterpriseName" style="width: 500px">
          <a-input allowClear v-model="formData.enterpriseName" placeholder="请输入企业名称" disabled />
        </a-form-model-item>
        <div style="margin: 0 15px 15px 0">
          <p>营业执照</p>
          <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
            @change="(e) => handleLicenseImageChange(e, 'busiLicensePic')" :customRequest="handleCustomUpload"
            :showUploadList="false">
            <img v-if="busiLicense.busiLicensePic" :src="`${$const.OssImagePrefix}/${busiLicense.busiLicensePic}`"
              class="upload-btn" style="width: 269px; height: 339px" />
            <a-button v-else class="upload-btn" style="width: 269px; height: 339px">点击上传<br />三证合一</a-button>
          </a-upload>
        </div>
        <a-input style="width: 500px; display: block" class="margin-bottom" allowClear
          v-model="formData.busiLicenseCode" placeholder="请输入社会统一信用代码" disabled />
        <a-input style="width: 500px; display: block" class="margin-bottom" allowClear
          v-model="formData.busiLicenseAvailable" placeholder="请输入营业执照有效期" disabled />
        <a-input style="width: 500px; display: block" class="margin-bottom" allowClear
          v-model="formData.legalmanLicenceName" placeholder="请输入法人姓名" disabled />
        <div class="flex-start" style="width: 850px; margin-bottom: 15px; flex-wrap: wrap">
          <a-form-model-item prop="handIdentityCard">
            <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
              @change="
                (e) => handleLicenseImageChange(e, 'legalmanLicenceHandpic')
              " :customRequest="handleCustomUpload" :showUploadList="false">
              <img v-if="busiLicense.legalmanLicenceHandpic"
                :src="`${$const.OssImagePrefix}/${busiLicense.legalmanLicenceHandpic}`" class="upload-btn-02" />
              <a-button v-else class="upload-btn-02">点击上传手持身份证图片</a-button>
            </a-upload>
          </a-form-model-item>

          <a-form-model-item prop="frontIdentityCard">
            <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
              @change="(e) => handleLicenseImageChange(e, 'legalmanLicencePic')" :customRequest="handleCustomUpload"
              :showUploadList="false">
              <img v-if="busiLicense.legalmanLicencePic"
                :src="`${$const.OssImagePrefix}/${busiLicense.legalmanLicencePic}`" class="upload-btn-02" />
              <a-button v-else class="upload-btn-02">点击上传身份证正面图片</a-button>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item prop="backIdentityCard">
            <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
              @change="
                (e) => handleLicenseImageChange(e, 'legalmanLicenceBackpic')
              " :customRequest="handleCustomUpload" :showUploadList="false">
              <img v-if="busiLicense.legalmanLicenceBackpic"
                :src="`${$const.OssImagePrefix}/${busiLicense.legalmanLicenceBackpic}`" class="upload-btn-02" />
              <a-button v-else class="upload-btn-02">点击上传身份证反面图片</a-button>
            </a-upload>
          </a-form-model-item>
        </div>
        <a-input style="width: 500px; display: block" class="margin-bottom" allowClear readOnly
          v-model="userInfo.personalResp.name" placeholder="请输入运营者姓名" disabled />
        <a-input style="width: 500px; display: block" class="margin-bottom" allowClear readOnly
          v-model="userInfo.personalResp.phone" placeholder="请输入运营者联系方式" disabled />
        <a-input style="width: 500px; display: block" class="margin-bottom" allowClear readOnly
          v-model="userInfo.personalResp.realName" placeholder="运营者微信" disabled />
      </template>
      <!-- <template title="简介认证" v-if="authType === 4">
        <a-form-model-item label="简介认证" prop="shopBrief" style="width: 500px">
          <a-textarea allowClear v-model="formData.shopBrief" placeholder="请输入" show-count :maxlength="200"
            :auto-size="{ minRows: 6 }" disabled />
        </a-form-model-item>
      </template> -->
       <template title="品牌故事" v-if="authType === 4">
        <a-form-model-item label="品牌故事认证" prop="shopBrief" style="width: 500px;">
          <a-textarea allowClear v-model="formData.shopBrief" placeholder="品牌故事不超过2000字符,含标点和空格" show-count
            :maxlength="2000" :auto-size="{ minRows: 6 }" disabled/>
        </a-form-model-item>
      </template>
       <template title="主页名称认证" v-if="authType === 14">
        <a-form-model-item label="主页名称认证" prop="shopName" style="width: 300px;">
           <a-input v-model="formData.shopName" placeholder="请输入主页名称" disabled />
        </a-form-model-item>
      </template>
      <template title="简介认证" v-if="authType === 15">
        <a-form-model-item label="简介认证" prop="intro" style="width: 500px;">
          <a-textarea allowClear v-model="formData.intro" placeholder="简介不超过100字符,含标点和空格" show-count
            :maxlength="100" :auto-size="{ minRows: 6 }" disabled />
        </a-form-model-item>
      </template>
      <template title="品牌认证" v-if="authType === 5">
        <a-form-model-item label="品牌认证" prop="brandName" style="width: 500px">
          <a-input allowClear v-model="formData.brandName" placeholder="请输入" disabled />
        </a-form-model-item>
        <p><span>商标注册证</span></p>
        <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
          <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
            @change="handleBrandRegistPic" :customRequest="handleCustomUpload" :showUploadList="false">
            <img v-if="brandImages.brandRegistPic" :src="`${$const.OssImagePrefix}/${brandImages.brandRegistPic}`"
              class="upload-btn-02" />
            <a-button v-else class="upload-btn-02">点击上传商标注册证图</a-button>
          </a-upload>

          <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
            @change="handleBrandLogoPic" :customRequest="handleCustomUpload" :showUploadList="false">
            <img v-if="brandImages.brandLogoPic" :src="`${$const.OssImagePrefix}/${brandImages.brandLogoPic}`"
              class="upload-btn-02" />
            <a-button v-else class="upload-btn-02">点击上传商标LOGO图</a-button>
          </a-upload>
        </div>
        <p><span>商标授权证</span></p>
        <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
          <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
            @change="handleBrandAuthorizePic" :customRequest="handleCustomUpload" :showUploadList="false">
            <img v-if="brandImages.brandAuthorizePic" :src="`${$const.OssImagePrefix}/${brandImages.brandAuthorizePic}`"
              class="upload-btn-02" />
            <a-button v-else class="upload-btn-02">点击上传商标授权书</a-button>
          </a-upload>
        </div>
      </template>
      <template title="经营地址认证" v-if="authType === 6">
        <a-form-model-item label="经营地址认证" prop="shopAdressDetail" style="width: 500px">
          <a-input allowClear v-model="formData.shopAdressDetail" placeholder="请输入" disabled />
        </a-form-model-item>
        <div class="justify-start">
          <p style="margin-right: 15px"><span>工作时间</span></p>
          <div class="flex-column">
            <div style="display:flex;width:250px">
              <a-select ref="select" v-model="formData.workDaysBegin" disabled style="flex:1" @focus="focus">
                <a-select-option value="周一">周一</a-select-option>
                <a-select-option value="周二">周二</a-select-option>
                <a-select-option value="周三">周三</a-select-option>
                <a-select-option value="周四">周四</a-select-option>
                <a-select-option value="周五">周五</a-select-option>
                <a-select-option value="周六">周六</a-select-option>
                <a-select-option value="周日">周日</a-select-option>
              </a-select>
              <div style="width: 30px;text-align: center">~</div>
              <a-select ref="select" v-model="formData.workDaysEnd" disabled style="flex:1" @focus="focus">
                <a-select-option value="周一">周一</a-select-option>
                <a-select-option value="周二">周二</a-select-option>
                <a-select-option value="周三">周三</a-select-option>
                <a-select-option value="周四">周四</a-select-option>
                <a-select-option value="周五">周五</a-select-option>
                <a-select-option value="周六">周六</a-select-option>
                <a-select-option value="周日">周日</a-select-option>
              </a-select>
            </div>
            <div style="display:flex;width:250px;margin-top: 10px;">
              <a-time-picker style="flex:1:" format="HH:mm" disabled valueFormat="HH:mm" :minuteStep="1"
                v-model="formData.workTimeBegin" />
              <div style="width: 30px;text-align: center">~</div>
              <a-time-picker style="flex:1:" format="HH:mm" disabled valueFormat="HH:mm" :minuteStep="1"
                v-model="formData.workTimeEnd" />
            </div>
          </div>
        </div>
        <p><span>选择地图上的定位</span></p>
        <div class="flex-start" style="width: 900px;margin-bottom: 15px;flex-wrap: wrap;">
          <div>
            <txMap ref="txMap" v-if="addrobj&&addrobj.lat" :lat="addrobj.lat||0" :lng="addrobj.lng||0" :isclick="false">
            </txMap>
          </div>
        </div>
        <p><span>上传经营地址签约合同图</span></p>
        <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template title="办公环境认证" v-if="authType === 9">
        <a-form-model-item label="办公环境认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" disabled />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="身份认证" v-if="authType === 2">
        <a-form-model-item label="身份认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 290px; margin-right: 15px" allowClear v-model="authItem.title" placeholder="请输入" />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
              <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)" :customRequest="handleCustomUpload" :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 9">
                <a-button class="upload-btn-02">认证图片</a-button>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="经历认证" v-if="authType === 12">
        <a-form-model-item label="经历认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 290px; margin-right: 15px" allowClear v-model="authItem.timePeriod"
                placeholder="请输入" value="2023-2024" disabled />
              <a-input style="width: 220px" allowClear v-model="authItem.title" placeholder="请输入" value="设计机构开业"
                disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="获奖认证" v-if="authType === 13">
        <a-form-model-item label="获奖认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 290px; margin-right: 15px" allowClear v-model="authItem.title" placeholder="请输入"
                value="2023-2024" disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="展览认证" v-if="authType === 11">
        <a-form-model-item label="展览认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 290px; margin-right: 15px" allowClear v-model="authItem.timePeriod"
                placeholder="请输入" value="2023-2024" disabled />
              <a-input style="width: 280px; margin-right: 10px" allowClear v-model="authItem.exhibitionHall"
                placeholder="请输入" value="设计机构开业" disabled />
              <a-input style="width: 270px" allowClear v-model="authItem.adress" placeholder="请输入" value="2023-2024"
                disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="店铺环境认证" v-if="authType === 10">
        <a-form-model-item label="店铺环境认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap;">
              <a-input style="width: 290px; margin-right: 15px;display:none;" allowClear v-model="authItem.title"
                placeholder="请输入" disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
              <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)" :customRequest="handleCustomUpload" :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 9">
                <a-button class="upload-btn-02">认证图片</a-button>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="线下实体店认证" v-if="authType === 8">
        <a-form-model-item label="线下实体店认证" prop="shopAdress" style="width: 500px">
          <a-input allowClear v-model="formData.shopAdressDetail" placeholder="请输入" disabled />
        </a-form-model-item>
        <div class="justify-start">
          <p style="margin-right: 15px"><span>工作时间</span></p>
          <div class="flex-column">
            <div style="display:flex;width:250px">
              <a-select ref="select" v-model="formData.workDaysBegin" disabled style="flex:1" @focus="focus">
                <a-select-option value="周一">周一</a-select-option>
                <a-select-option value="周二">周二</a-select-option>
                <a-select-option value="周三">周三</a-select-option>
                <a-select-option value="周四">周四</a-select-option>
                <a-select-option value="周五">周五</a-select-option>
                <a-select-option value="周六">周六</a-select-option>
                <a-select-option value="周日">周日</a-select-option>
              </a-select>
              <div style="width: 30px;text-align: center">~</div>
              <a-select ref="select" v-model="formData.workDaysEnd" disabled style="flex:1" @focus="focus">
                <a-select-option value="周一">周一</a-select-option>
                <a-select-option value="周二">周二</a-select-option>
                <a-select-option value="周三">周三</a-select-option>
                <a-select-option value="周四">周四</a-select-option>
                <a-select-option value="周五">周五</a-select-option>
                <a-select-option value="周六">周六</a-select-option>
                <a-select-option value="周日">周日</a-select-option>
              </a-select>
            </div>
            <div style="display:flex;width:250px;margin-top: 10px;">
              <a-time-picker style="flex:1:" format="HH:mm" disabled valueFormat="HH:mm" :minuteStep="1"
                v-model="formData.workTimeBegin" />
              <div style="width: 30px;text-align: center">~</div>
              <a-time-picker style="flex:1:" format="HH:mm" disabled valueFormat="HH:mm" :minuteStep="1"
                v-model="formData.workTimeEnd" />
            </div>
          </div>
        </div>
        <p><span>选择地图上的定位</span></p>
        <div class="flex-start" style="width: 900px;margin-bottom: 15px;flex-wrap: wrap;">
          <div>
            <txMap ref="txMap" v-if="addrobj&&addrobj.lat" :lat="addrobj.lat||0" :lng="addrobj.lng||0" :isclick="false">
            </txMap>
          </div>
        </div>
        <p><span>上传经营地址签约合同图</span></p>
        <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <a-input style="width: 180px; margin-right: 15px; display:none" allowClear v-model="authItem.title"
                placeholder="请输入" disabled />
            </div>
            <div class="flex-start" style="width: 900px; margin-bottom: 15px; flex-wrap: wrap">
              <div v-for="(img) in authItem.imgs" :key="img" class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0">
                <img v-if="img" :src="`${$const.OssImagePrefix}/${img}`" class="upload-btn-02" />
              </div>
              <a-upload style="margin: 0 15px 15px 0" name="file" :multiple="false" :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)" :customRequest="handleCustomUpload" :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 9">
                <a-button class="upload-btn-02">经营地址签约合同图</a-button>
              </a-upload>
            </div>
          </div>
        </div>
      </template>
      <a-form-model-item label="状态" prop="state">
        <a-radio-group v-model="formData.state" :defaultValue="1" disabled>
          <a-radio :value="0">认证待审核</a-radio>
          <a-radio :value="1">已认证</a-radio>
          <a-radio :value="2">未通过认证</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
  import DetailTop from '../../components/detail-top.vue'
  import * as Settle from "@/api/user/settle";
  import txMap from "../../components/txmap";
  import {
    uploadFile
  } from "@/components/AliOss/OssClient.js";

  export default {
    components: {
      DetailTop,
      txMap
    },
    name: "detail",
    data() {
      return {
        authType: 1,
        userType: 1,
        userInfo: {},
        personalResp: {},
        formData: {
          setlleType: 0,
          shopName: undefined,
          shopBrief: "",
          enterpriseName: undefined,
          manageType: undefined,
          platform: 0,
          state: 0,
          shopAdress: "",
          shopAdressDetail: "",
          workDaysBegin: "周一",
          workDaysEnd: "周一",
          workTimeBegin: "",
          workTimeEnd: "",
        },
        idCards: {
          handIdentityCard: "",
          frontIdentityCard: "",
          backIdentityCard: "",
        },
        brandImages: {
          brandLogoPic: "",
          brandRegistPic: "",
          brandAuthorizePic: "",
        },
        busiLicense: {
          busiLicensePic: "",
          legalmanLicencePic: "",
          legalmanLicenceBackpic: "",
          legalmanLicenceHandpic: "",
        },
        authFormData: [{
          timePeriod: "",
          title: "",
          imgs: [],
        }, ],
        rules: {
          setlleType: [{
            required: true,
            message: "请选择入驻类型",
            trigger: "change"
          }],
          shopName: [{
            required: true,
            message: "请输入店铺名称",
            trigger: "blur"
          }],
          shopBrief: [{
            required: true,
            message: "请输入简介",
            trigger: "blur"
          }],
          enterpriseName: [{
            required: true,
            message: "请输入企业名称",
            trigger: "blur"
          }],
          brandName: [{
            required: true,
            message: "请输入品牌名称",
            trigger: "blur"
          }],
          manageType: [{
            required: true,
            message: "请选择经营类型",
            trigger: "change"
          }],
          platform: [{
            required: true,
            message: "请选择入驻平台",
            trigger: "change"
          }],
          state: [{
            required: true,
            message: "请选择状态",
            trigger: "change"
          }],
        },
        bsId: "",
        addrobj: {}
      };
    },
    created() {
      this.id = this.$route.query.id;
      this.bsId = this.$route.query.bsId;
    },
    mounted() {
      this.authType = Number(this.$route.query.authType);
      this.userType = this.$route.query.userType;
      this.queryDetailById();
    },
    methods: {
      queryDetailById() {
        Settle.applyDetail({
            applyId: this.id,
            businessType: Number(this.userType),
          }).then((res) => {
            const data = res.data || {};
            this.formData = data || {};
            this.userInfo = data || {}
            this.userInfo.businessResp = res.data.businessInfoResp || {}
            this.userInfo.personalResp = res.data.personalResp || {}
            if (this.authType === 1) {
              this.idCards = data;
            } else if (this.authType === 2) {
              this.authFormData[0].imgs = data.pics;
              this.authFormData[0].title = data.identityName;
            } else if (this.authType === 3) {
              this.busiLicense.busiLicensePic = data.busiLicensePic
              this.busiLicense.legalmanLicenceBackpic = data.legalmanLicenceBackpic
              this.busiLicense.legalmanLicenceHandpic = data.legalmanLicenceHandpic
              this.busiLicense.legalmanLicencePic = data.legalmanLicencePic
              this.formData.enterpriseName = data.enterpriseName;
              this.formData.busiLicenseCode = data.busiLicenseCode;
              this.formData.busiLicenseAvailable = data.busiLicenseAvailable;
              this.formData.legalmanLicenceName = data.legalmanLicenceName;
              this.formData.legalmanLicenceCode = data.legalmanLicenceCode;
            } else if (this.authType === 4) {
              this.formData.shopBrief = data.shopBrief;
            } else if (this.authType === 5) {
              this.formData.brandName = data.brandName;
              this.brandImages.brandLogoPic = data.brandLogoPic;
              this.brandImages.brandRegistPic = data.brandRegistPic;
              this.brandImages.brandAuthorizePic = data.brandAuthorizePic;
            } else if (this.authType === 6 || this.authType === 8) {
              this.formData.shopAdress = data.shopAdress;
              if (data.shopAdress) {
                this.addrobj = JSON.parse(this.formData.shopAdress);
              }
              this.formData.shopAdressDetail = data.shopAdressDetail;
              this.formData.workDaysBegin = data.workDaysBegin
              this.formData.workDaysEnd = data.workDaysEnd
              this.formData.workTimeBegin = data.workTimeBegin
              this.formData.workTimeEnd = data.workTimeEnd
              this.authFormData[0].imgs = data.pics
            } else if (this.authType === 9) {
              this.authFormData[0].timePeriod = data.timePeriod
              this.authFormData[0].title = data.title
              this.authFormData[0].exhibitionHall = data.exhibitionHall
              this.authFormData[0].imgs = data.pics
            } else if (this.authType === 10) {
              this.authFormData[0].imgs = data.pics
            } else if (this.authType === 11) {
              let _data = data.exhibitionContentItem
              this.exMap(_data)
            } else if (this.authType === 12) {
              let _data = data.experienceContentItem
              this.exMap(_data)
            } else if (this.authType === 13) {
              let _data = data.awardContentItem
              this.exMap(_data)
            }
            this.formData.state = data.state;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      exMap(data) {
        this.authFormData = []
        data.map(item => {
          return this.authFormData.push({
            timePeriod: item.timePeriod,
            title: item.title,
            exhibitionHall: item.exhibitionHall,
            adress: item.adress,
            imgs: item.pics,
          })
        })
      },
      handleCustomUpload(options) {
        const _file = options.file;
        uploadFile({
          bizType: "trend",
          type: "file",
          fileName: _file.name,
          targetName: new Date().getTime() + "_" + _file.name,
          file: _file,
        }).then((result) => {
          if (result.res.statusCode === 200) {
            options.onSuccess(result, options);
          } else {
            this.$message.error("上传失败");
          }
        });
      },
      focus() {},
      handleChangeIdCard(info) {
        if (info.file.status === "done") {
          this.idCards.handIdentityCard = info.file.response.name;
        }
      },
      handleChangePositive(info) {
        if (info.file.status === "done") {
          this.idCards.frontIdentityCard = info.file.response.name;
        }
      },
      handleLicenseImageChange(info, field) {
        if (info.file.status === "done") {
          this.busiLicense[field] = info.file.response.name;
        }
      },
      handleChangeOther(info) {
        if (info.file.status === "done") {
          this.idCards.backIdentityCard = info.file.response.name;
        }
      },
      handleBrandRegistPic(info) {
        if (info.file.status === "done") {
          this.brandImages.brandRegistPic = info.file.response.name;
        }
      },
      handleBrandLogoPic(info) {
        if (info.file.status === "done") {
          this.brandImages.brandLogoPic = info.file.response.name;
        }
      },
      handleBrandAuthorizePic(info) {
        if (info.file.status === "done") {
          this.brandImages.brandAuthorizePic = info.file.response.name;
        }
      },
      handleAuthImage(info, index) {
        if (info.file.status === "done") {
          this.authFormData[index].imgs.push(info.file.response.name);
        }
      },
      handleAddAuth() {
        this.authFormData = [
          ...this.authFormData,
          {
            timePeriod: "",
            title: "",
            imgs: [],
          },
        ];
      },
      handleBeforeUpload(file) {
        this.uploadTempFile = file;
        return true;
      }
    }
  };
</script>

<style scoped lang="less">
  .container {
    padding: 20px;
    background: #ffffff;

    .label {
      width: 100px;
    }
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .upload-btn {
    width: 411px;
    height: 244px;
    font-weight: bold;
    font-size: 20px;
    background: #f2f2f2;
  }

  .upload-btn-02 {
    width: 285px;
    height: 244px;
    font-weight: bold;
    font-size: 20px;
    background: #f2f2f2;
  }

  .justify-start {
    display: flex;
    justify-content: flex-start;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  /deep/ .ant-form-item-required {
    font-size: 18px;
    font-weight: bold;
  }

  .img-wrap {
    position: relative;
  }

  .img-delete {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
</style>
